<!--
 * @Description: 
 * @Author: 琢磨先生
 * @Date: 2022-05-09 10:59:17
 * @LastEditors: 琢磨先生
 * @LastEditTime: 2022-10-11 17:39:58
-->

<template>
  <el-drawer
    v-model="drawer"
    :title="title"
    direction="rtl"
    size="800px"
    :show-close="false"
    :close-on-click-modal="false"
  >
    <el-form :model="form" ref="form" :rules="rules" label-width="140px">
      <el-form-item label="城市区域" prop="area_id">
        <el-select placeholder="请选择城市区域" v-model="form.area_id">
          <el-option
            v-for="item in areas"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          ></el-option>
        </el-select>
      </el-form-item>

      <el-form-item label="选择分类" prop="typeIds">
        <el-cascader
          :options="types"
          v-model="form.typeIds"
          :props="{ label: 'name', value: 'id', expandTrigger: 'hover' }"
        >
        </el-cascader>
      </el-form-item>

      <el-form-item label="名称" prop="name">
        <el-col :span="14">
          <el-input v-model="form.name" placeholder="请输入名称"></el-input>
        </el-col>
      </el-form-item>
      <el-form-item label="定位" prop="lng" v-if="form.area_id">
        <el-button type="text" @click="openMap">
          <el-icon><add-location /></el-icon>
          坐标拾取</el-button
        >{{ form.lng ? form.lng + "," + form.lat : "" }}
      </el-form-item>

      <el-form-item label="热门推荐" prop="is_hot">
        <el-switch v-model="form.is_hot"> </el-switch>
      </el-form-item>
      <el-form-item label="停用" prop="is_stop">
        <el-switch v-model="form.is_stop"> </el-switch>
      </el-form-item>
      <el-form-item>
        <el-button
          type="primary"
          @click="onSubmit"
          :disabled="loading"
          :loading="loading"
          >确定</el-button
        >
        <el-button @click="cancelClick" :disabled="loading">取消</el-button>
      </el-form-item>
    </el-form>
  </el-drawer>
  <map-picker
    :area="area"
    :lng="form.lng"
    :lat="form.lat"
    :change="pickerChange"
  ></map-picker>
</template>
<script>
import MapPicker from "@/views/map/map_picker";

export default {
  components: {
    MapPicker,
  },
  data() {
    return {
      loading: false,
      drawer: false,
      title: "编辑商圈、景点",
      area: null,
      form: {},
      rules: {
        name: [
          {
            required: true,
            message: "请输入名称",
            trigger: "blur",
          },
        ],
        area_id: [
          {
            required: true,
            message: "请输入名称",
            trigger: "blur",
          },
        ],
        lng: [
          {
            required: true,
            message: "请拾取坐标位置",
            trigger: "blur",
          },
        ],
        typeIds: [
          {
            required: true,
            message: "请选择分类",
            trigger: "blur",
          },
        ],
      },
    };
  },
  props: ["item", "types", "areas", "reload"],
  watch: {
    item: {
      handler() {
        if (this.item) {
          this.drawer = true;
          this.form = Object.assign({},this.item);
        }
      },
    },
  },

  methods: {
    /**
     * 提交保存
     */
    onSubmit() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.loading = true;
          this.form.type_id = this.form.typeIds[this.form.typeIds.length - 1];
          console.log(this.form);
          this.$http
            .post("/admin/v1/location/edit", this.form)
            .then((res) => {
              this.loading = false;
              if (res.code == 0) {
                this.drawer = false;
                this.$message({
                  type: "success",
                  message: res.msg,
                });
                if (this.reload) {
                  this.reload();
                }
              }
            })
            .cath(() => {
              this.loading = false;
            });
        }
      });
    },
    /**
     * 取消
     */
    cancelClick() {
      this.drawer = false;
      if (this.reload) {
        this.reload();
      }
    },
    /**
     * 打开定位
     */
    openMap() {
      var area = this.areas.find((x) => x.id == this.form.area_id);
      this.area = area;
    },
    /**
     * 坐标选取更改
     */
    pickerChange(val) {
      this.area = null;
      if (val) {
        this.form.lat = val.lat;
        this.form.lng = val.lng;
      }
    },
  },
};
</script>